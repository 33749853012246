.nav-container {
  background: rgb(0, 0, 0);
}

.event-container {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.event-image {
  width: 45%;
}

.event-category {
  background-color: var(--red);
  color: white;
  padding: 10px 15px;
  margin: 2% 10%;
}

.event-description {
  width: 55%;
  padding-left: 15px;
}

.event-image {
  padding-right: 15px;
}

.event-image img {
  width: 100%;
}

.event-description .header {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--blue);
}

.event-description p {
  color: rgba(0, 0, 0, 0.8);
  line-height: 2.2rem;
}

.event-description .procedure ol {
  line-height: 1.7rem;
  padding-left: 20px;
}

.event-description .procedure ol li::marker {
  color: var(--yellow);
}

.event-description .procedure ol li span {
  color: var(--red);
}

.register-btn {
  margin-top: 1.2rem;
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 20px;
}

@media screen and (max-width: 800px) {
  .event-container {
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    font-size: 15px;
  }

  .event-image,
  .event-description {
    width: 100%;
  }

  .event-description .header {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin-top: 2rem;
  }

  .event-category {
    margin: 1% 2%;
  }
}
