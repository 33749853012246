nav ul li a {
  color: var(--blue);
}

.contact__tablet {
  width: 70%;
  height: 800px;
  /*border: 1px solid rgba(36 ,37 ,90, 0.55);*/
  margin: 50px auto;
  box-shadow: 0 12px 24px rgba(36, 37, 90, 0.28);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(36, 37, 90, 0.75);
}

.contact__tablet .tablet__screen {
  width: 90%;
  height: 85%;
  border-radius: 50px;
  border: 1px solid rgba(36, 37, 90, 0.55);
  background: var(--white);
}

.contact__tablet .tablet__screen .contact__message {
  width: 90%;
  margin: 35px auto 16px;
  font-size: 0.97rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: rgba(0, 0, 0, 0.75);
}

.contact__tablet .tablet__screen .contact__details {
  width: 90%;
  margin: 15px auto;
  display: flex;
}

.contact__tablet .tablet__screen .contact__details div {
  font-size: 0.98rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 12px;
}

.contact__tablet .tablet__screen .contact__details div:first-child {
  width: 45%;
}

.contact__tablet .tablet__screen .contact__details div:last-child .text {
  text-align: center;
  line-height: 1.6rem;
}

.contact__tablet .tablet__screen .contact__details i {
  font-size: 32px;
  color: var(--yellow);
  margin-right: 6px;
}

.contact__tablet .tablet__screen .input__row {
  width: 90%;
  margin: 0 auto 30px;
}

.contact__tablet .tablet__screen .input__row:first-child {
  display: flex;
  margin: 20px auto;
}

.contact__tablet .tablet__screen .input__row:first-child .input__group {
  width: 48%;
}

.contact__tablet
  .tablet__screen
  .input__row:first-child
  .input__group:last-child {
  margin-left: auto;
}

.contact__tablet .tablet__screen .input__row .input__group input {
  width: 100%;
  padding: 10px 20px;
  background: var(--white);
  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-bottom: 1px solid #cccccc;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
}

.contact__tablet .tablet__screen .input__row .input__group input:focus,
.contact__tablet .tablet__screen .input__row .input__group textarea:focus {
  outline: 0;
  transition: 0.3s ease-in;
}

.contact__tablet .tablet__screen .input__row .input__group input:focus {
  border-bottom: 1px solid var(--yellow);
}

.contact__tablet .tablet__screen .input__row .input__group textarea:focus {
  border: 1px solid var(--yellow);
}

.contact__tablet .tablet__screen .input__row .input__group textarea {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  padding: 10px 20px;
}

input::placeholder,
textarea::placeholder {
  color: rgba(36, 37, 90, 0.85);
  font-weight: 700;
}

.button__container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.button__container button {
  width: 80px;
  height: 80px;
  background: rgba(247, 148, 29, 0.6);
  border: 2px solid var(--yellow);
  color: var(--white);
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 50%;
  font-size: 1rem;
}

.button__container button:hover {
  cursor: pointer;
  background: var(--yellow);
  transition: 0.2s ease-in;
}

/* .log,
.process {
  width: 90%;
} */

@media screen and (max-width: 800px) {
  .contact__tablet {
    width: 95%;
    border-radius: 25px;
    height: 890px;
  }

  .contact__tablet .tablet__screen {
    height: 95%;
    border-radius: 25px;
  }

  .contact__tablet .tablet__screen .contact__details {
    flex-direction: column;
  }

  .contact__tablet .tablet__screen .contact__details div:first-child {
    width: 100%;
    margin-bottom: 15px;
    justify-content: center;
  }
}
