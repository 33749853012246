.navbar.static {
  margin: 18px auto;
}

nav ul li a {
  color: var(--blue);
}

.page__header {
  height: 17rem;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.page__header .header__content {
  width: 25%;
}

.page__header .header__content i {
  font-size: 65px;
  color: var(--yellow);
}

.page__header .header__content .txt {
  font-size: 0.95rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 20px 0;
  line-height: 1.7rem;
}

.manufacture {
  height: 700px;
  /*flex-direction: row-reverse;*/
}

.manufacture .section__content {
  width: 100%;
  height: 100%;
  background: rgba(36, 37, 90, 0.88);
  align-items: center;
  position: relative;
}

/*.manufacture .section__image{
    width: 35%;
    height: 100%;
}

.manufacture .section__image img{
    width: 100%;
    height: 100%;
    filter: brightness(75%);
}*/

.manufacture .section__content .txt {
  width: 85%;
  margin: 0 auto;
}

.manufacture .section__content .txt h3 {
  font-size: 1.35rem;
  color: var(--white);
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.manufacture .section__content .txt p {
  color: #f1f1f1;
  font-size: 0.95rem;
  line-height: 1.8rem;
  margin-bottom: 20px;
}

.manufacture .section__content .txt p a {
  font-weight: 700;
  color: var(--yellow);
}
.manufacture .section__content .txt p a:hover {
  color: var(--red);
  transition: 0.2s ease-in;
}

.tech__services {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
}

.tech__services .service__box {
  height: 340px;
  padding: 25px 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(36, 37, 90, 0.18);
  position: relative;
}

.tech__services .service__box .box__header {
  color: var(--blue);
  display: flex;
  align-items: center;
  height: 46px;
}

.tech__services .service__box .box__header .txt {
  width: 85%;
  font-size: 1rem;
  font-weight: 700;
}

.tech__services .service__box .box__header i {
  margin-left: auto;
  font-size: 24px;
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 20px;
}

.tech__services .service__box .box__content {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.4rem;
}

.tech__services .service__box .box__content .hidden__content {
  display: none;
}

.tech__services .service__box .read__more {
  color: var(--blue);
  font-weight: 700;
  font-size: 0.9rem;
  margin: 10px 0;
  position: absolute;
  bottom: 5%;
}

.tech__services .service__box .read__more:hover {
  cursor: pointer;
}

.tech__services .service__box .read__more i {
  font-size: 10px;
}

.training {
  padding: 45px 0;
  background: linear-gradient(rgba(36, 37, 90, 0.58), rgba(36, 37, 90, 0.7));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.training .content__holder {
  width: 75%;
  margin: 0 auto;
}

.training .inner__content {
  width: 100%;
  color: var(--white);
}

.training .inner__content .header {
  font-size: 1.35rem;
  font-weight: 700;
  margin-bottom: 22px;
}

.training .inner__content .content {
  font-size: 0.95rem;
  color: #f1f1f1;
  line-height: 1.5rem;
}
.training .inner__content .content p {
  margin: 16px 0;
}

.training .inner__content .content .image__grid {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 20px;
  justify-content: center;
  margin: 30px 0;
}

.training .inner__content .content .image__grid .training__image {
  height: 220px;
}

.training .inner__content .content .image__grid .training__image img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.rotate {
  animation: rotate 0.8s;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.read__more__content,
.hvac__services {
  padding: 30px 0;
}

.hvac__services {
  background: rgba(0, 0, 0, 0.01);
}

.read__more__content {
  margin: 25px auto;
  width: 90%;
  display: none;

  transition: 0.3s ease-in-out;
}

.hvac__services .inner {
  width: 90%;
  margin: 50px auto;
}

.read__more__content .header,
.hvac__services .header {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--blue);
}

.read__more__content .content,
.hvac__services .content {
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5rem;
}
.read__more__content .content {
  font-weight: 600;
}

.read__more__content .content p,
.hvac__services .content p {
  margin: 20px 0;
}

.read__more__content .content p a {
  font-weight: 600;
  color: var(--yellow);
}

.read__more__content .content p a:hover {
  color: var(--red);
}

.read__more__content .close__button {
  font-size: 1.1rem;
  color: var(--yellow);
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin-top: 50px;
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  align-items: center;
}

.read__more__content .close__button i {
  font-size: 16px;
  margin-left: 2px;
}

.read__more__content .close__button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.more_content_animation {
  transition: 0.3s ease-in-out;
  display: block;
}

@media screen and (max-width: 600px) {
  .tech__services {
    grid-template-columns: 85%;
  }

  .page__header .header__content {
    width: 75%;
  }

  .training .inner__content .content .image__grid {
    grid-template-columns: 48% 48%;
  }

  .training .inner__content .content .image__grid .training__image {
    height: 180px;
  }

  .training .content__holder {
    width: 90%;
  }
}

@media screen and (min-width: 601px) and (max-width: 799px) {
  .tech__services {
    grid-template-columns: 45% 45%;
  }

  .tech__services .service__box {
    height: 355px;
  }

  .page__header .header__content {
    width: 60%;
  }

  .training .inner__content .content .image__grid {
    grid-template-columns: 48% 48%;
  }
}

@media screen and (max-width: 800px) {
  .navbar .logo.static__logo .mobile__menu {
    color: var(--blue);
  }

  .manufacture {
    flex-direction: column;
    height: auto;
    background: rgba(36, 37, 90, 0.88);
  }
  .manufacture .section__content {
    background: transparent;
    width: 85%;
    margin: 35px auto;
  }

  .training .inner__content {
    width: 100%;
  }

  .manufacture .section__content .txt {
    width: 90%;
  }
}

/*
.page__banner .flowing__dot{
    position: absolute;
    top: 10%;
    left: 5%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 6px 8px rgba(255, 255, 255, 0.8), 4px 8px 12px rgba(255, 255, 255, 0.8);
}

.page__banner .flowing__dot:first-child{
    top: 10%;
    left: 5%;
    animation: drop 10s linear infinite;
}

.page__banner .flowing__dot:nth-child(2){
    top: 5%;
    left: 10%;
}

.page__banner .flowing__dot:nth-child(3){
    top: 15%;
    left: 15%;
}

.page__banner .flowing__dot:nth-child(4){
    top: 20%;
    left: 20%;
}

.page__banner .flowing__dot:nth-child(5){
    top: 8%;
    left: 25%;
}
.page__banner .flowing__dot:nth-child(6){
    top: 25%;
    left: 35%;
}

.page__banner .flowing__dot:nth-child(7){
    top: 15%;
    left: 48%;
}

.page__banner .flowing__dot:nth-child(8){
    top: 35%;
    left: 63%;
}

.page__banner .flowing__dot:nth-child(9){
    top: 45%;
    left: 77%;
}

.page__banner .flowing__dot:nth-child(10){
    top: 27%;
    left: 90%;
}

.page__banner .flowing__dot:nth-child(11) {
    top: 8%;
    left: 96%;
}


@keyframes drop{
    from{
        top: 10%;
    }

    to {
        top: 100%;
    }

}
*/
