nav.static ul li a {
  color: var(--white);
}

nav.fixed ul li a {
  color: var(--blue);
}

.page__banner {
  width: 100%;
  height: 70vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.5)),
    url("../images/scifi.jpg");
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 2rem 0;
}

.page__banner .banner__content {
  width: 90%;
  height: calc(100% - 54px);
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.page__banner .banner__content .inner__content {
  width: 55%;
}

.page__banner .banner__content .inner__content h4 {
  font-size: 1.5rem;
  color: var(--white);
  margin-bottom: 18px;
}

.page__banner .banner__content .inner__content p {
  font-size: 0.95rem;
  color: #f5f5f5;
  line-height: 1.6rem;
}

.who__we__are {
  width: 75%;
  margin: 45px auto;
  display: flex;
}

.who__we__are .icon__box {
  width: 120px;
}

.who__we__are .icon__box .icon__row {
  display: flex;
}

.who__we__are .icon__box .icon__row:first-child {
  margin-bottom: 10px;
}

.who__we__are .icon__box .icon__row .icon {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
}

.who__we__are .icon__box .icon__row .icon:hover {
  background: rgba(247, 148, 29, 0.19);
  transition: 0.2s ease-in;
  cursor: pointer;
}

.who__we__are .icon__box .icon__row .icon i {
  font-size: 40px;
  color: var(--yellow);
}

.who__we__are .icon__box .icon__row .icon:last-child {
  margin-left: auto;
}

.who__we__are .text__content {
  width: calc(100% - 200px);
  padding: 8px 18px;
}

.who__we__are .text__content h4 {
  font-size: 1.45rem;
  font-family: "Open Sans", sans-serif;
  color: var(--blue);
  margin-bottom: 16px;
}

.who__we__are .text__content p {
  font-size: 0.97rem;
  line-height: 1.6rem;
  margin-bottom: 15px;
}

.who__we__are .text__content .services {
  margin-top: 2px;
  padding: 15px;
  font-size: 0.97rem;
  line-height: 1.6rem;
}

.who__we__are .text__content .services ul {
  margin: 15px auto;
  width: 75%;
}

.who__we__are .text__content .services ul li {
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--blue);
}

.who__we__are .text__content .link__container {
  display: flex;
}

.who__we__are .text__content .link__container a {
  margin-left: auto;
}

.core__values {
  width: 75%;
  margin: 85px auto;
}

.core__values header {
  width: 100%;
  text-align: center;
  font-size: 1.45rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 25px;
}

.core__values .inner__row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.core__values .inner__row {
  margin-bottom: 25px;
}

.core__values .inner__row .core__box {
  width: 22%;
  height: 200px;
  box-shadow: 0 4px 12px rgba(36, 37, 90, 0.08);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 18px;
}

.core__values .inner__row .core__box .icon__box {
  width: 75px;
  height: 75px;
  background: transparent;

  /*border: 3px solid var(--blue)*/
  border-radius: 50%;

  margin-bottom: 25px;
  position: relative;
}

.core__values .inner__row .core__box .icon__box:hover {
  animation: switch 0.6s;
  background: var(--blue);
  cursor: pointer;
}

.core__values .inner__row .core__box .icon__box:hover i {
  color: var(--white);
}

@keyframes switch {
  0% {
    background: transparent;
  }

  50% {
    background: var(--black);
    color: var(--white);
  }

  100% {
    background: var(--blue);
    color: var(--white);
  }
}

.core__values .inner__row .core__box .icon__box .icon__border {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 3px solid rgba(36, 37, 90, 0.65);
}

.core__values .inner__row .core__box .icon__box i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--blue);
  font-size: 45px;
}

.core__values .inner__row .core__box .core__text {
  font-size: 1rem;
  font-weight: 700;
  color: rgba(236, 28, 36, 0.65);
}

.fill {
  animation: fill 2.5s;
}

@keyframes fill {
  0% {
    transform: rotate(210deg);
    border-top: 3px solid rgba(36, 37, 90, 0.15);
    border-left: 3px solid rgba(36, 37, 90, 0.65);
    border-bottom: 3px solid rgba(36, 37, 90, 0.65);
    border-right: 3px solid rgba(36, 37, 90, 0.15);
  }

  100% {
    transform: rotate(360deg);
    border: 3px solid rgba(36, 37, 90, 0.75);
  }
}

.mission,
.vision {
  width: 100%;
  height: 350px;
}

.mission {
  background: var(--blue);
  box-shadow:
    0 6px 12px rgba(0, 0, 0, 0.19),
    0 5px 10px rgba(0, 0, 0, 0.2);
}

.content__container {
  width: 85%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.content__container .inner__tag {
  width: 40%;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
}

.mission .content__container .inner__tag {
  color: var(--white);
}

.vision .content__container .inner__tag {
  color: var(--blue);
}

.content__container .inner__text {
  width: 60%;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 700;
}

.mission .content__container .inner__text {
  color: #e5e5e5;
}

.vision .content__container .inner__text {
  color: rgba(0, 0, 0, 0.65);
}

@media screen and (max-width: 800px) {
  nav ul li a {
    color: var(--blue);
  }
  .page__banner .banner__content .inner__content {
    width: 100%;
  }

  .who__we__are {
    flex-direction: column;
    width: 90%;
  }

  .who__we__are .text__content {
    width: 100%;
  }

  .core__values {
    width: 95%;
  }

  .core__values .inner__row {
    flex-direction: column;
    margin-bottom: 0;
  }

  .core__values .inner__row .core__box {
    width: 55%;
    margin-bottom: 18px;
  }

  .mission,
  .vision {
    height: auto;
  }

  .content__container {
    width: 90%;
    flex-direction: column;
  }

  .content__container .inner__tag,
  .content__container .inner__text {
    width: 100%;
  }

  .content__container .inner__tag {
    margin: 40px 0;
  }

  .content__container .inner__text {
    margin: 0 0 60px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1199px) {
  .page__banner .banner__content .inner__content {
    width: 100%;
  }
}
