.trainings {
  margin-left: 15%;
  margin-right: 15%;
}

.training-section {
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-section .title {
  font-size: 30px;
  font-family: "Rufina", serif;
  font-weight: bold;
  color: rgba(34, 31, 31, 0.897);
  margin-bottom: 20px;
  text-align: center;
}

.training-section .title span {
  color: var(--red);
}

.training-section .brief {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--red);
  font-weight: 700;
  font-size: 0.9rem;
  margin: 10px 0;
}

.training-section .brief i {
  font-size: 0.8rem;
  margin: 0px 5px 0px 5px;
}

.training-section .description {
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.programs {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
}

.programs .course__box {
  height: max-content;
  padding: 25px 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(36, 37, 90, 0.18);
  position: relative;
}

.programs .course__box .box__header {
  color: var(--blue);
  display: flex;
  align-items: center;
  height: 46px;
}

.programs .course__box .box__header .txt {
  width: 85%;
  font-size: 1rem;
  font-weight: 700;
}

.programs .course__box .box__header i {
  margin-left: auto;
  font-size: 24px;
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 0px 0 10px;
}

.programs .course__box .box__content {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.4rem;
}

.programs .course__box .box__content .hidden__content {
  display: none;
}

.programs .course__box .read__more {
  color: var(--blue);
  font-weight: 700;
  font-size: 0.9rem;
  margin: 10px 0;
}

.programs .course__box .read__more:hover {
  cursor: pointer;
}

.programs .course__box .read__more i {
  font-size: 10px;
}

.course__box .image {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
}

.course__box .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.trainings a {
  text-decoration: none;
}

.training-page {
  margin: 5% 15% 5% 15%;
  display: flex;
  justify-content: space-between;
}

.training-page .aside-div {
  width: 25%;
  background-color: var(--blue);
  color: white;
  padding: 25px;
  border-radius: 10px;
  height: max-content;
}

.training-page aside ul li {
  text-decoration: none;
  list-style: none;
  line-height: 2;
  cursor: pointer;
  font-weight: 500;
}

.training-page aside ul li:hover {
  color: var(--yellow);
}

.training-page main {
  width: 70%;
}

.training-page main ul li::marker {
  color: var(--yellow);
  font-size: 20px;
  margin-top: 10px;
}

.training-page main ul li {
  font-size: 0.97rem;
  line-height: 1.6rem;
  margin-bottom: 15px;
  margin-left: 30px;
  list-style-type: circle;
}

.training-page main ul li span {
  color: var(--blue);
  font-weight: bolder;
}

.training-heading h4 {
  font-size: 1.45rem;
  font-family: "Open Sans", sans-serif;
  color: var(--blue);
  margin-bottom: 16px;
}

.training-page main p {
  font-size: 0.97rem;
  line-height: 1.6rem;
}

.learn {
  margin-bottom: 5%;
}

.benefits {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 10%;
  margin: 50px 0px 50px 0px;
}

.benefit-card {
  text-align: center;
  margin: 10px 0px 10px 0px;
  line-height: 3rem;
}

.benefit-card i {
  background-color: var(--blue);
  padding: 15px;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  width: 55px;
}

.benefit-card i:hover {
  color: var(--blue);
  background-color: white;
  border: 2px solid var(--blue);
}

.benefit-card h4 {
  color: var(--yellow);
}

.program-requirements {
  background-color: var(--blue);
  color: white;
  padding: 25px 30px 25px 30px;
}

.program-requirements h4 {
  color: white;
}

.training-fees {
  margin-top: 5%;
}

.fees_box {
  display: grid;
  grid-template-columns: 30% 65%;
  grid-gap: 10%;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.fees_fee {
  text-align: center;
  font-size: 2rem;
  border-right: 2px solid var(--blue);
  line-height: 1.5;
}

.fees_fee h4 {
  font-weight: 900;
  color: var(--blue);
}

.fees_fee h5 {
  font-weight: 900;
  color: var(--red);
  text-decoration: line-through;
}

.training-faq {
  width: 100%;
  padding: 0;
}

.fixed-top {
  width: 17.5%;
  background-color: var(--blue);
  color: white;
  padding: 25px;
  border-radius: 10px;
  height: max-content;
  position: fixed;
  top: 10%;
  z-index: 1000; /* High z-index to keep it above other content */
}

.main-top {
  margin-left: 30%;
}

.training_bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 0px;
}

@media screen and (max-width: 715px) {
  .trainings {
    margin-left: 5%;
    margin-right: 5%;
  }

  .training-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .training-section .title {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .programs {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    padding: 0px 0;
  }

  .training-page aside {
    display: none;
  }

  .training-page {
    margin: 5%;
  }

  .training-page main {
    width: 100%;
  }

  .main-top {
    margin-left: 0%;
  }

  .benefits {
    grid-template-columns: 100%;
    grid-gap: 0%;
  }

  .fees_box {
    grid-template-columns: 35% 65%;
    grid-gap: 10px;
    padding: 0px;
    padding-bottom: 30px;
  }

  .fees_fee {
    text-align: center;
    font-size: 1.5rem;
    border-right: none;
  }

  .fees_info {
    border-left: 2px solid var(--blue);
    padding-left: 15px;
  }

  .program-requirements {
    padding: 10px 15px 10px 15px;
  }

  .benefit-card {
    margin: 50px 0px 50px 0px;
  }
}

@media screen and (min-width: 716px) and (max-width: 1099px) {
  .trainings {
    margin-left: 5%;
    margin-right: 5%;
  }

  .training-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .training-section .title {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .programs {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
    padding: 0px 0;
  }

  .training-page {
    margin: 5% 5% 5% 5%;
  }

  .fixed-top {
    width: 25%;
  }

  .training-page .aside-div {
    width: 28%;
  }

  .fees_box {
    grid-template-columns: 35% 65%;
    grid-gap: 10px;
    padding: 0px;
    padding-bottom: 30px;
  }

  .fees_fee {
    text-align: center;
    font-size: 2rem;
    border-right: none;
  }

  .fees_info {
    border-left: 2px solid var(--blue);
    padding-left: 15px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .trainings {
    margin-left: 5%;
    margin-right: 5%;
  }

  .training-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .training-section .title {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .programs {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-gap: 20px;
    padding: 0px 0;
  }

  .training-page {
    margin: 5% 5% 5% 5%;
  }

  .fixed-top {
    width: 25%;
  }

  .training-page .aside-div {
    width: 28%;
  }
}
