nav.static ul li a {
  color: var(--white);
}

nav.fixed ul li a {
  color: var(--blue);
}

/* .page__banner {
  width: 100%;
  height: 70vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 2rem 0;
}

.page__banner .banner__content {
  width: 90%;
  height: calc(100% - 54px);
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.page__banner .banner__content .inner__content {
  width: 35%;
}

.page__banner .banner__content .inner__content h4 {
  font-size: 1.5rem;
  color: var(--white);
  margin-bottom: 18px;
}

.page__banner .banner__content .inner__content p {
  font-size: 0.95rem;
  color: #f5f5f5;
  line-height: 1.6rem;
} */

.blog__detail .blog__detail__banner {
  width: 40%;
  padding-right: 20px;
}

.blog__detail .blog__detail__banner img {
  width: 100%;
}

.blog__detail {
  background: rgba(0, 0, 0, 0.01);
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  padding-left: 10%;
  padding-right: 10%;
}

.blog__detail .inner {
  width: 60%;
}

.blog__detail .inner .header {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--blue);
}

.blog__detail .inner .content {
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5rem;
}

.blog__detail .inner .content p {
  margin: 20px 0;
}

.blog__detail .blog__detail__banner .reaction {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.blog__detail .blog__detail__banner .reaction button {
  font-size: 1rem;
  padding: 8px 50px 8px 50px;
  border-radius: 10px;
  border: none;
}

.blog__detail .blog__detail__banner .reaction button:hover {
  cursor: pointer;
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 20px;
}

.robo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: 50px auto;
}

.robo-section .dash {
  border-top: 1px solid black;
  width: 47%;
}

.robo-section i {
  font-size: 22px;
  color: grey;
}

.comments {
  padding-left: 10%;
}

.comments .title {
  font-size: 30px;
  font-family: "Rufina", serif;
  font-weight: bold;
  color: rgba(34, 31, 31, 0.897);
  margin-bottom: 20px;
}

.comments p {
  font-size: 18px;
  color: grey;
}

.parents-review {
  background-color: rgba(75, 79, 88, 0.1);
}

.comments .comment-box {
  display: flex;
  width: 70%;
  margin-top: 15px;
}

.comments .comment-box .aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.comments .comment-box .aside img {
  width: 60px;
}

.comments .comment-box .aside .comment-line {
  border-left: 2px solid red;
  height: 100%;
}

.comments .comment-box .main {
  padding: 20px;
  background-color: rgba(75, 79, 88, 0.1);
  border-radius: 8px;
  height: max-content;
  width: 70%;
}

.comments .comment-box .main .content {
  margin-top: 5px;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.85);
}

.comments .comment-box .main h4 {
  font-size: 1.2rem;
}

.comment__section {
  padding-left: 10%;
  margin-top: 15px;
}

.comment__section h3 {
  margin-bottom: 10px;
}

.comment__section div {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.comment__section div input,
.comment__section div textarea {
  font-size: 1rem;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid gray;
  margin-bottom: 10px;
}

.comments .comment-box .main .datetime {
  text-align: right;
  font-size: 0.9rem;
  padding-top: 5px;
}

.share_link {
  display: none;
  text-align: center;
  opacity: 0;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
}

.share_link.show {
  display: block;
  opacity: 1;
}

.share_link h4 {
  padding-bottom: 10px;
}

.share_link .social__media__links {
  display: inline;
}

.share_link .social__media__links a i {
  margin-right: 15px;
  font-size: 35px;
  color: black;
}

#content_id {
  white-space: pre-line;
}

.liked {
  background-color: red;
  color: white;
}

.notliked {
  background-color: white;
  color: black;
}

.liked:hover {
  background-color: red;
  color: white;
}

@media screen and (max-width: 1000px) {
  nav ul li a {
    color: var(--blue);
  }

  .page__banner {
    background-size: auto 100%;
    background-position: center;
  }

  .blog__detail {
    flex-direction: column-reverse;
    padding-left: 5%;
    padding-right: 5%;
  }

  .blog__detail .inner,
  .blog__detail .blog__detail__banner {
    width: 100%;
    padding: 0px;
  }

  .blog__detail .blog__detail__banner img {
    padding-top: 20px;
  }
  .blog__detail .blog__detail__banner .reaction button {
    font-size: 0.8rem;
    padding: 5px 30px 5px 30px;
  }

  .comments {
    padding-left: 5%;
  }

  .comments .title {
    font-size: 25px;
    font-family: "Rufina", serif;
    font-weight: bold;
    color: rgba(34, 31, 31, 0.897);
    margin-bottom: 20px;
  }

  .comments p {
    font-size: 18px;
    color: grey;
  }

  .comments .comment-box {
    width: 95%;
  }

  .comments .comment-box .aside img {
    width: 40px;
  }

  .comments .comment-box .main .content {
    line-height: 1.5rem;
    font-size: 1rem;
  }

  .comments .comment-box .main h4 {
    font-size: 1.2rem;
  }

  .comments .comment-box .main {
    padding: 10px;
    width: 100%;
  }

  .comments .comment-box .main h4 {
    font-size: 1rem;
  }

  .comment__section {
    padding-left: 5%;
  }

  .comment__section div {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .comment__section div input,
  .comment__section div textarea {
    font-size: 0.9rem;
  }
}
