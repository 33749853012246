.blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
}

.blog .blog_box {
  display: flex;
  width: 60%;
  padding: 25px 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(36, 37, 90, 0.18);
  margin-top: 25px;
  margin-bottom: 25px;
}

.blog .blog_box .box__header {
  color: var(--blue);
  display: flex;
  align-items: center;
  height: 46px;
}

.blog .blog_box .box__header .txt {
  width: 85%;
  font-size: 1.5rem;
  font-weight: 700;
}

.blog .blog_box .box__header i {
  margin-left: auto;
  font-size: 24px;
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 20px;
}

.blog .blog_box .blog_image {
  width: 25%;
  margin-right: 25px;
  height: 200px;
}

.blog .blog_box .blog_content {
  width: 75%;
}

.blog .blog_box .blog_image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 8px;
}

.blog .blog_box .box__content {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 2rem;
}

.box_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.blog .blog_box .box__content .hidden__content {
  display: none;
}

.blog .blog_box .read__more {
  color: var(--blue);
  font-weight: 700;
  font-size: 1.1rem;
  margin: 10px 0;
  bottom: 5%;
}

.blog .blog_box .read__more:hover {
  cursor: pointer;
}

.blog .blog_box .read__more i {
  font-size: 13px;
}

.blog a {
  text-decoration: none;
}

.blog .event-category {
  margin: 1% 20%;
  width: 60%;
}

@media screen and (max-width: 900px) {
  .blog .blog_box {
    width: 90%;
  }

  .blog .blog_box .blog_image {
    width: 30%;
    margin-bottom: 30px;
    height: 180px;
  }

  .blog .blog_box .blog_content {
    width: 70%;
    padding: 0 auto;
  }

  .blog .blog_box .box__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .blog .blog_box .blog_image img {
    width: 100%;
    border-radius: 8px;
    padding-bottom: 15px;
  }

  .blog .blog_box .box__header .txt {
    font-size: 1.2rem;
  }

  .blog .blog_box .read__more,
  .box_date {
    font-size: 1rem;
    margin: 0px;
    margin-top: 10px;
  }

  .blog .blog_box .read__more i {
    font-size: 10px;
  }

  .bottom__border {
    margin: 8px 0 10px;
  }

  .box_bottom {
    padding-bottom: 0px;
  }

  .blog .event-category {
    margin: 1% 10%;
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .blog .blog_box {
    width: 90%;
    flex-direction: column;
  }

  .blog .blog_box .blog_image {
    width: 100%;
    margin-bottom: 15px;
    height: 250px;
  }

  .blog .blog_box .blog_content {
    width: 100%;
  }

  .blog .blog_box .blog_image img {
    width: 100%;
    border-radius: 8px;
  }

  .blog .blog_box .box__header .txt {
    font-size: 1.2rem;
  }

  .blog .blog_box .read__more,
  .box_date {
    font-size: 1rem;
  }

  .blog .event-category {
    margin: 1% 10%;
    width: 90%;
  }
}
