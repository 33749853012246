.nav-container {
  background: rgb(0, 0, 0);
}

.register h3 {
  text-align: center;
  font-size: 16px;
}

.event-register {
  border: 2px solid red;
  padding: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 25px;
  display: block;
  background-color: rgba(255, 255, 255, 0.95);
}

.event-layer {
  background-image: url("../images/blue-pattern.png");
  background-size: 500px;
  background-repeat: repeat-x repeat-y;
  margin-right: 15%;
  margin-left: 15%;
}

.register_type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register_type .straight-line {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  width: 2px;
  background-color: var(--blue);
}

.bottom__border {
  width: 30px;
  height: 3px;
  background: var(--yellow);
  margin: 8px 0 20px;
}

.student-info .input__row {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 20px;
}

.row_inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 20px;
}

.input__row .input__group input,
.input__row__2 .input__group input,
select {
  width: 90%;
  padding: 10px 0px;
  background: var(--white);
  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-bottom: 1px solid #cccccc;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
}

.input__row__2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input__row__2 .image-upload {
  margin: 25px 0 5px 0;
}

/* .image-upload {
    display: flex;
    align-items: flex-end;
}

.image-upload .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    line-height: 1.3rem;
    background-color: var(--red);
    text-align: center;
    margin-right: 10px;
} */

.add-btn {
  text-align: right;
}

.child-list {
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  border: 1px solid red;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.event-register button {
  padding: 10px 20px;
  font-size: 0.85rem;
}

.event-register {
  font-size: 15px;
}

.event_button_selected {
  font-size: 1.05rem;
  padding: 12px 29px;
  color: var(--white);
  background: var(--red);
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid var(--red);
  cursor: pointer;
}

.event_button {
  font-size: 1.05rem;
  padding: 12px 29px;
  color: var(--red);
  background: var(--white);
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid var(--red);
  cursor: pointer;
}

.organization {
  display: none;
}

.individual {
  display: none;
}

.image-upload {
  position: relative;
}

.hidden {
  display: none;
}

#imageBox,
#paymentBox {
  background-color: var(--red);
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.image-upload {
  width: 120px;
  height: 120px;
  border: 1px solid var(--red);
}

.parent-dflex .register-info {
  width: 85%;
}

.parent-dflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#uploadedImage,
#paymentImage {
  max-width: 100%;
  max-height: 100%;
}

#imageBox span,
#paymentBox span {
  display: block;
  padding-top: 40px;
}

#imageBox img,
#paymentBox img {
  display: none;
  object-fit: cover;
  height: 100%;
}

.submit-btn {
  text-align: center;
}

.image-upload p {
  padding-top: 50px;
  padding-bottom: 40px;
}

.success-show {
  display: block;
}

.success-hide {
  display: none;
}

.registration-success {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin: 100px 0;
}

.registration-success .container {
  text-align: center;
}

.circle {
  display: flex;
  position: relative;
  width: 300px;
  height: 300px;
  border: 3px solid var(--red);
  /* Red color */
  border-radius: 50%;
  overflow: hidden;
  animation: scaleUp 0.5s ease-in-out;
  justify-content: center;
  align-items: center;
}

.success-container img {
  width: 200px;
  height: 200px;
}

.text {
  margin-top: 20px;
  font-size: 25px;
  color: #333;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 716px) {
  .event-layer {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
  }

  .input__row {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
  }

  .input__row .input__group input,
  .input__row__2 .input__group input {
    width: 100%;
  }

  .row_inputs {
    width: 100%;
    grid-template-columns: 100%;
  }

  .image-upload {
    margin-top: 20px;
  }

  .dob label {
    position: absolute;
    margin-top: 10px;
    padding-left: 20px;
  }

  .proof-container {
    left: 0%;
  }

  .parent-dflex .register-info {
    width: 100%;
  }

  .parent-dflex {
    flex-direction: column;
    align-items: flex-start;
  }

  .row_inputs {
    grid-template-columns: 100%;
    grid-gap: 20px;
  }
}

@media screen and (min-width: 716px) and (max-width: 1099px) {
  .event-layer {
    margin-right: 5%;
    margin-left: 5%;
    font-size: 12px;
  }

  .row_inputs {
    width: 100%;
    grid-template-columns: 50% 50%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .event-layer {
    margin-right: 10%;
    margin-left: 10%;
  }
}
