@import url("https://fonts.googleapis.com/css2?family=Hind&family=PT+Sans&display=swap");

body {
  background-color: #ffffff;
  font-style: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-container {
  background: rgb(0, 0, 0);
}

@media screen and (min-width: 1201px) {
  .box {
    width: 30%;
  }

  .programme-section {
    width: 50%;
  }

  .programme-section .numbers {
    display: flex;
    justify-content: space-between;
  }

  .page-width {
    width: calc(100% - 15rem);
  }

  .first-text-container .second-part {
    display: flex;
    justify-content: space-between;
  }

  .first-text-container .second-part .content {
    width: 48%;
  }

  .c-section .content {
    width: 48%;
  }

  .programme-section .numbers .numbers-element {
    width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .rating-section .box-section {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .parents-review .heading {
    width: 50%;
  }

  .box {
    width: 48%;
    margin-bottom: 3rem;
  }

  .box:last-child {
    margin: 0 auto 3rem;
  }

  .c-section {
    width: 70%;
  }

  .month-section {
    width: 70%;
  }

  .robo-section {
    width: 40%;
  }

  .y-section {
    width: 70%;
  }

  .page-width {
    width: calc(100% - 8.5rem);
  }

  .first-text-container .second-part {
    display: flex;
    justify-content: space-between;
  }

  .first-text-container .second-part .content {
    width: 48%;
  }

  .programme-section {
    width: 80%;
  }

  .programme-section .numbers {
    display: flex;
    justify-content: space-between;
  }

  .programme-section .numbers .numbers-element {
    width: 25%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767px) {
  .box-section {
    flex-direction: column;
  }

  .box {
    width: 70%;
    margin-bottom: 3rem;
  }

  .page-width {
    width: calc(100% - 8.5rem);
  }

  .first-text-container .second-part {
    flex-direction: column;
  }

  .c-section {
    width: 70%;
  }

  .programme-section {
    width: 80%;
  }

  .programme-section .numbers {
    display: flex;
    justify-content: space-between;
  }

  .programme-section .numbers .numbers-element {
    width: 25%;
  }

  .future-section .create-part {
    width: calc(100% - 5rem);
  }

  .second-part .details {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 650px) {
  .box-section {
    width: 100%;
    flex-direction: column;
  }

  .box {
    width: 80%;
    margin-bottom: 3rem;
    padding: 20px 25px;
  }

  .page-width {
    width: calc(100% - 3.5rem);
  }

  .first-text-container {
    flex-direction: column;
  }

  .c-section {
    flex-direction: column;
  }

  .c-section .overview {
    margin-bottom: 30px;
  }

  .programme-section .numbers {
    flex-direction: column;
    align-items: center;
  }

  .programme-section .numbers-element {
    margin: 50px 0px;
  }

  .container .second-part .details {
    margin-bottom: 80px;
  }

  .programme-section .numbers .numbers-element {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .future-section .create-part {
    width: calc(100% - 5rem);
    flex-direction: column;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 85vh;
}

.container .image-container {
  width: 100%;
  height: 100%;
}

.container .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.container .image-container .paragraph-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(5, 4, 4, 0.08);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.paragraph-container h1 {
  color: #ffffff;
  margin-bottom: 20px;
  font-family: "Rufina", serif;
}

@media screen and (min-width: 1024px) {
  .paragraph-container h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 1023px) {
  .paragraph-container h1 {
    font-size: 28px;
  }
}

.page-width {
  margin: 0 auto;
}

@media screen and (max-width: 749px) {
  .page-width {
    padding: 2.5rem 0;
  }
}

@media screen and (min-width: 750px) {
  .page-width {
    padding: 5rem 0;
  }
}

.paragraph-container .paragraph-one {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 3rem;
}

@media screen and (max-width: 650px) {
  .paragraph-container .paragraph-one,
  .paragraph-container h1 {
    max-width: 100%;
  }
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
  .paragraph-container .paragraph-one,
  .paragraph-container h1 {
    max-width: 75%;
  }
}

@media screen and (min-width: 1025px) {
  .paragraph-container .paragraph-one,
  .paragraph-container h1 {
    max-width: 30%;
  }
}

.paragraph-container button {
  background-color: #424242;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.paragraph-container button:hover {
  background-color: rgb(45, 143, 188);
}

.paragraph-container .request-button {
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
}

.first-text-container {
  width: 100%;
  background-color: rgba(75, 79, 88, 0.1);
  padding: 3rem 0;
}

.second-part .details {
  color: grey;
  font-size: 18px;
}

@media screen and (max-width: 650px) {
  .second-part .details {
    margin-bottom: 3.5rem;
  }
}

.second-part p {
  margin-bottom: 20px;
}

.first-text-container span {
  float: left;
  width: 0.7em;
  font-size: 400%;
  line-height: 80%;
}

.second-part-image {
  height: 20rem;
}

.second-part-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.future-section {
  margin-top: 4rem;
}

.future-section .header {
  font-size: 40px;
  font-weight: bolder;
  color: rgba(34, 31, 31, 0.897);
  margin-bottom: 20px;
  max-width: 80%;
  font-family: "Rufina", serif;
}

.future-section .quote {
  margin-bottom: 5px;
}
.future-section .create-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 749px) {
  .future-section .create-part .content {
    width: 100%;
  }

  .future-section .create-part .content:first-child,
  .future-section .create-part .line {
    margin-bottom: 2.5rem;
  }
}

@media screen and (min-width: 750px) {
  .future-section .create-part .content {
    width: 48%;
  }
}

.future-section p {
  font-size: 18px;
  color: grey;
}

.future-section .paragraph-two p {
  margin-bottom: 5px;
}

.future-section .paragraph-two {
  padding-top: 50px;
}

.line {
  width: 60px;
  height: 3.5px;
  background-color: royalblue;
}

.second-part-border p {
  margin: 20px 0;
}

.bottom-line {
  border-top: 1px solid black;
  width: 82%;
  padding: 0;
}

.programme-section {
  text-align: center;
}

@media screen and (min-width: 750px) {
  .programme-section {
    width: calc(70% - 10rem);
  }
}
.programme-section .title {
  margin: 20px 0;
  font-size: 18px;
  color: grey;
  font-weight: bold;
}

.programme-section .numbers {
  margin-bottom: 3rem;
}

.programme-section .numbers span {
  display: block;
}

.colour {
  font-size: 69px;
  color: royalblue;
  font-weight: bolder;
}

.below {
  color: grey;
}

.programme-section button {
  background-color: #424242;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.programme-section button:hover {
  background-color: rgb(45, 143, 188);
}

.programme-section .request-button {
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
}

.photos-section {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.photos-section .title {
  font-size: 40px;
  color: rgba(34, 31, 31, 0.897);
  font-weight: bold;
}

.photos-section .title-line {
  border-top: 2px solid black;
  width: 40%;
  margin: 30px auto 25px;
}

.photos-section .photo-slider {
  margin: 30px auto;
  position: relative;
}

.photos-section .photo-slider .slider-item {
  width: 100%;
}

.photos-section .photo-slider .slider-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 749px) {
  .photos-section .photo-slider {
    width: calc(100% - 5rem);
  }

  .photos-section .photo-slider .slider-item {
    height: 18rem;
  }
}

@media screen and (min-width: 750px) {
  .photos-section .photo-slider .slider-item {
    height: 30rem;
  }
}

@media screen and (min-width: 751px) and (max-width: 1023px) {
  .photos-section .photo-slider {
    width: calc(85% - 5rem);
  }
}

@media screen and (min-width: 1024px) {
  .photos-section .photo-slider {
    width: calc(55% - 5rem);
  }
}

.level-slides-container {
  position: relative;
  margin-top: 5rem;
}

.level-slides .slider-image {
  height: 15rem;
}

.level-slides .slider-image img,
.level-slides .partner-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.level-slides .partners {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.level-slides .partner-images {
  width: 250px;
}

.level-slides .slider-label {
  font-size: 1.05rem;
  font-weight: 700;
  text-align: center;
  margin: 1.2rem 0;
}

.custom-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #173570;
}

.custom-button:hover {
  transition: 0.3s ease;
  cursor: pointer;
  opacity: 0.7;
}

.custom-button.custom-prev {
  left: -7.5%;
}

.custom-button.custom-next {
  right: -7.5%;
}

.owl-dots {
  padding-top: 20px;
}

.level-slides-container .custom-button {
  top: 40%;
  z-index: 9999;
}
@media screen and (min-width: 750px) {
  .level-slides-container .custom-button.custom-prev {
    left: -4.5%;
  }

  .level-slides-container .custom-button.custom-next {
    right: -4.5%;
  }
}

@media screen and (max-width: 749px) {
  .custom-button.custom-prev {
    left: -3%;
  }

  .custom-button.custom-next {
    right: -3%;
  }
}

.backup-section .title {
  font-size: 40px;
  color: rgba(34, 31, 31, 0.897);
  text-align: center;
  font-weight: bold;
}

.backup-section .first-paragraph {
  font-size: 18px;
  color: grey;
  margin: 20px 0 20px;
}

.backup-section .first-paragraph span {
  color: blue;
}

.backup-section .list-section {
  font-size: 18px;
  color: grey;
  margin-bottom: 2rem;
}

.quote-section {
  background-color: rgba(75, 79, 88, 0.1);
}

.quote-section .page-width i {
  font-size: 3rem;
  color: rgb(19, 61, 188);
}

.quote-section .main-quote {
  font-size: 55px;
  font-family: "Georgia", serif;
  font-style: italic;
  margin: 30px 0 30px;
  color: rgba(34, 31, 31, 0.897);
}

.quote-section .quote-director {
  color: rgba(34, 31, 31, 0.897);
  font-size: 25px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.quote-section button {
  background-color: #424242;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 3rem;
}

.quote-section button:hover {
  background-color: rgb(45, 143, 188);
}

.quote-section .request-button {
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
}

.quote-section .button-holder-two {
  text-align: center;
}

.c-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

@media screen and (max-width: 749px) {
  .c-section .content {
    width: 100%;
  }
}

@media screen and (min-width: 750px) {
  .c-section .content {
    width: 48%;
  }
}

.c-section .overview p {
  font-size: 40px;
  max-width: 35%;
  font-family: "Rufina", serif;
  font-weight: bold;
  color: rgba(34, 31, 31, 0.897);
}

.c-section .line {
  margin-bottom: 30px;
}

.c-section .levels p {
  font-size: 18px;
  color: grey;
}

.month-section .title {
  font-size: 30px;
  font-family: "Rufina", serif;
  font-weight: bold;
  color: rgba(34, 31, 31, 0.897);
  margin-bottom: 20px;
}

.month-section p {
  font-size: 18px;
  color: grey;
}

.robo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: 50px auto;
}

.robo-section .dash {
  border-top: 1px solid black;
  width: 47%;
}

.robo-section i {
  font-size: 22px;
  color: grey;
}

.y-section .title {
  font-size: 30px;
  font-family: "Rufina", serif;
  font-weight: bold;
  color: rgba(34, 31, 31, 0.897);
  margin-bottom: 20px;
}

.y-section p {
  font-size: 18px;
  color: grey;
}

.parents-review {
  background-color: rgba(75, 79, 88, 0.1);
}

.parents-review .heading {
  font-size: 40px;
  font-family: "Rufina", serif;
  color: rgba(34, 31, 31, 0.897);
  font-weight: bold;
  text-align: center;
  max-width: fit-content;
  padding: 3rem 0;
}

.parents-review .line {
  width: 90px;
  height: 3px;
  background-color: grey;
  margin: auto;
}

.rating-section {
  display: flex;
}

.rating-items {
  opacity: 0;
}

.rating-items.animated-section {
  animation: slideIntoView 1.5s;
  opacity: 1;
}

@keyframes slideIntoView {
  from {
    opacity: 0.1;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.box {
  height: 230px;
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  font-family: "PT-Serif", serif;
}

.box-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  padding: 0 0 5rem;
}

.testimonial-one .stars {
  display: flex;
  margin-bottom: 20px;
  filter: invert(27%) sepia(0%) saturate(2461%) hue-rotate(317deg)
    brightness(93%) contrast(75%);
}

.testimonial-one .stars img {
  width: 15px;
  height: 20px;
}

.testimonial-one .word {
  color: grey;
  margin-bottom: 20px;
}

.testimonial-one .ron {
  display: flex;
  text-align: left;
}

.testimonial-one .person img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 10px;
}

.ron .image-name {
  max-width: 50%;
  color: grey;
  font-size: 18px;
}

.ron .image-name :first-child {
  font-size: 0.85rem;
}

.ron .image-name :last-child {
  font-size: 16px;
}

.testimonial-two .stars {
  display: flex;
  margin-bottom: 20px;
  filter: invert(27%) sepia(0%) saturate(2461%) hue-rotate(317deg)
    brightness(93%) contrast(75%);
}

.testimonial-two .stars img {
  width: 15px;
  height: 20px;
}

.testimonial-two .word {
  color: grey;
  margin-bottom: 20px;
}

.testimonial-two .lily {
  display: flex;
  text-align: left;
}

.testimonial-two .person img {
  width: 4rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 10px;
}

.lily .image-name {
  max-width: 50%;
  color: grey;
  font-size: 18px;
}

.lily .image-name :first-child {
  font-size: 0.85rem;
}

.lily .image-name :last-child {
  font-size: 16px;
}

.testimonial-three .stars {
  display: flex;
  margin-bottom: 20px;
  filter: invert(27%) sepia(0%) saturate(2461%) hue-rotate(317deg)
    brightness(93%) contrast(75%);
}

.testimonial-three .stars img {
  width: 15px;
  height: 20px;
}

.testimonial-three .word {
  color: grey;
  margin-bottom: 20px;
}

.testimonial-three .jeson {
  display: flex;
  text-align: left;
}

.testimonial-three .person img {
  width: 4rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 10px;
}

.jeson .image-name {
  max-width: 50%;
  color: grey;
  font-size: 0.9rem;
}

.jeson .image-name :first-child {
  font-size: 0.9rem;
}

.jeson .image-name :last-child {
  font-size: 0.85rem;
}

.last-section button {
  background-color: #424242;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 3rem;
}

.last-section button:hover {
  background-color: rgb(45, 143, 188);
}

.last-section .request-button {
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
}

.last-section .button-holder-three {
  text-align: center;
}

.out-of-view {
  display: none;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box .word {
  font-size: 0.95rem;
}

.faq-section {
  margin: 0 auto;
  padding: 6.5rem 0;
}

.faq-section .section-heading {
  text-align: center;
}

.faq-section .section-heading h3 {
  font-size: 2.75rem;
}

.faq-section .section-heading .caption {
  text-align: center;
  margin: 1.2rem auto;
}

.faq-section .questions {
  margin: 2.5rem 0;
}

.faq-section .questions .accordion-main {
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  width: 100%;
  padding: 0 1.5rem;
  height: 64px;
  overflow-y: hidden;
}

.faq-section .questions .accordion-main:not(:last-child) {
  margin-bottom: 20px;
}

.questions .accordion-main .accordion-header {
  height: 64px;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.questions .accordion-main .accordion-header .caret-down {
  display: none;
  font-size: 16px;
}

.questions .accordion-main .accordion-content {
  line-height: 1.5rem;
  font-size: 0.95rem;
  padding: 0 1.5rem;
  color: rgba(0, 0, 0, 0.85);
  min-height: 80px;
}

.questions .accordion-main .accordion-content b {
  font-weight: 700;
}

.questions .accordion-main .accordion-header span {
  margin-left: 0.75rem;
}

.questions .accordion-main.content-visible .accordion-header {
  color: #173570;
  font-weight: 700;
}

@media screen and (max-width: 650px) {
  .faq-section {
    width: calc(100% - 1.5rem);
  }

  .level-slides .slider-label {
    width: 340px;
  }
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
  .faq-section {
    width: calc(85% - 3.5rem);
  }
}

@media screen and (min-width: 1025px) {
  .faq-section {
    width: calc(55% - 3rem);
  }

  .faq-section .section-heading .caption {
    width: calc(85% - 1.5rem);
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #173570;
}
