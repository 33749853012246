@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans:wght@700&display=swap");

:root {
  --white: #ffffff;
  --black: #000000;
  --blue: #24255a;
  --red: #ec1c24;
  --yellow: #f7941d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-family: "PT Sans", sans-serif;
  font-style: inherit;
}

nav {
  display: flex;
  align-items: center;
}

.navbar {
  width: 90%;
  margin: 0 auto;
  padding: 1.5rem 0;
  transition: top 1s;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--red);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1201px) {
  .navbar {
    width: calc(100% - 15rem);
  }
}

.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--white);
  z-index: 99;
  width: 100%;
  padding: 2rem 3.75rem;
}

nav .logo a img {
  width: 50px;
  height: 50px;
}

nav ul {
  list-style: none;
  margin-left: auto;
  width: fit-content;
  width: -moz-fit-content;
}

nav ul li {
  width: fit-content;
  width: -moz-fit-content;
  float: left;
  text-align: center;
}

nav ul li:not(:first-child) {
  margin-left: 2rem;
}

.nav-container nav ul li a {
  font-size: 0.95rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--white);
}

.white-nav-container nav ul li a {
  font-size: 0.95rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--blue);
}

.fixed ul li a {
  color: var(--blue);
}

nav ul li a:hover,
nav ul li .alternate:hover {
  color: var(--red);
  transition: 0.2s ease-in;
}

nav ul li:last-child a,
.nav__modal .modal__content .links__container .link:last-child a {
  padding: 10px 20px;
  background: var(--red);
  color: var(--white) !important;
  border-radius: 4px;
}

.navbar .logo .mobile__menu {
  display: none;
}

.flexible {
  display: flex;
}

.banner-images img {
  animation: opacityAnimation 0.5s;
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.button__red {
  font-size: 1.05rem;
  padding: 12px 29px;
  color: var(--white);
  background: var(--red);
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid var(--red);
  cursor: pointer;
}

.button__red:hover,
nav ul li:last-child a:hover {
  background: transparent;
  color: var(--red) !important;
  transition: 0.3s ease-in;
  border: 1px solid var(--red);
}

.button__alternate {
  padding: 12px 30px;
  background: transparent;
  border: 2px solid var(--blue);
  color: var(--blue);
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
}

.button__alternate:hover {
  background: var(--blue);
  color: var(--white);
  transition: 0.3s ease-in;
}

.button-blue {
  background: var(--blue);
  color: var(--white);
  padding: 12px 30px;
  border: 1px solid transparent;
  text-decoration: none;
  font-weight: 600;
}

.button-blue:hover {
  opacity: 0.7;
  cursor: pointer;
  transition: 0.3s ease;
}

footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 45px 45px 20px;
  background: var(--black);
  margin-bottom: -15px;
}

.inner__section {
  width: 35%;
}

.inner__section .footer__section {
  width: 50%;
}

.inner__section:last-child {
  width: 30%;
}

.inner__section:last-child .footer__section {
  width: 100%;
}

.inner__section .footer__section a {
  color: #cccccc;
  font-size: 0.8rem;
  text-decoration: none;
  width: 30px;
}

.inner__section .footer__section ul {
  list-style: none;
}

.inner__section .footer__section ul li {
  padding: 8px 0;
  width: 80%;
}

.inner__section .footer__section .section__header {
  color: var(--white);
  font-size: 0.9rem;
  padding-bottom: 15px;
  font-weight: 700;
}

.inner__section .footer__section .social__media__links {
  display: inline;
}

.inner__section .footer__section .social__media__links a i {
  margin-right: 15px;
  font-size: 35px;
}

.inner__section .footer__section:last-child .inner__content {
  float: right;
}

.inner__section .footer__section:last-child .inner__content .logo {
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}

.inner__section .footer__section:last-child .inner__content .logo img {
  width: 100%;
  height: 100%;
}

.inner__section .footer__section:last-child .inner__content .rights {
  font-size: 0.65rem;
  color: var(--white);
}

.shift {
  animation: shift 0.8s;
}

.hidden {
  display: none !important;
}

@keyframes shift {
  from {
    position: static;
    background: transparent;
    width: 90%;
    padding: 20px;
  }

  to {
    position: fixed;
    top: 0;
    background: var(--white);
    width: 100%;
    padding: 20px;
  }
}

@-webkit-keyframes shift {
  from {
    position: static;
    background: transparent;
    width: 90%;
  }

  to {
    position: fixed;
    top: 0;
    background: var(--white);
    width: 100%;
  }
}

@-moz-keyframes shift {
  from {
    position: static;
    background: transparent;
    width: 90%;
    padding: 0;
  }

  to {
    position: fixed;
    top: 0;
    background: var(--white);
    width: 100%;
    padding: 20px;
  }
}

@-o-keyframes shift {
  from {
    position: static;
    background: transparent;
    width: 90%;
    padding: 0;
  }

  to {
    position: fixed;
    top: 0;
    background: var(--white);
    width: 100%;
    padding: 20px;
  }
}

.change__height {
  animation: heighten 0.4s;
}

.lessen__height {
  animation: lessen 0.2s;
}

@keyframes heighten {
  from {
    height: 79px;
  }

  to {
    height: 373px;
  }
}

@-webkit-keyframes heighten {
  from {
    height: 79px;
  }

  to {
    height: 373px;
  }
}

@-moz-keyframes heighten {
  from {
    height: 79px;
  }

  to {
    height: 373px;
  }
}

@-o-keyframes heighten {
  from {
    height: 79px;
  }

  to {
    height: 373px;
  }
}

@keyframes lessen {
  from {
    height: 373px;
  }

  to {
    height: 79px;
  }
}

@-webkit-keyframes lessen {
  from {
    height: 373px;
  }

  to {
    height: 79px;
  }
}

@-moz-keyframes lessen {
  from {
    height: 373px;
  }

  to {
    height: 79px;
  }
}

@-o-keyframes lessen {
  from {
    height: 373px;
  }

  to {
    height: 79px;
  }
}

.contact__tablet .log,
.contact__tablet .process {
  font-weight: 700;
  font-size: 0.95rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log,
.process {
  font-weight: 700;
  font-size: 0.95rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.log.error__log {
  color: var(--red);
}

.log.success__log {
  color: mediumseagreen;
}
.log i {
  font-size: 20px;
  margin-right: 5px;
}

.process {
  color: var(--blue);
}

#loader {
  width: 18px;
  height: 18px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  border-top: 2px solid var(--blue);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.join__community {
  height: 730px;
  position: relative;
  background: #fcfcfc;
}

.join__community .section__content {
  position: absolute;
  width: 90%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.join__community .section__content .text__content,
.join__community .section__content .form__content {
  width: 50%;
  height: auto;
}

.slide__left {
  animation: slide-left 1s;
}

.slide__right {
  animation: slide-right 1s;
}

.lessen__modal {
  animation: lessen-modal 1s;
}

.slide-up {
  top: -200px;
}

.slide-down {
  top: 0;
}

@keyframes lessen-modal {
  from {
    margin-left: 0;
    opacity: 1;
  }

  to {
    margin-left: -150px;
    opacity: 0.2;
  }
}

@keyframes slide-left {
  from {
    margin-left: -150px;
    opacity: 0.2;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes lessen-modal {
  from {
    margin-left: 0;
    opacity: 1;
  }

  to {
    margin-left: -150px;
    opacity: 0.2;
  }
}

@-moz-keyframes lessen-modal {
  from {
    margin-left: 0;
    opacity: 1;
  }

  to {
    margin-left: -150px;
    opacity: 0.2;
  }
}

@-webkit-keyframes slide-left {
  from {
    margin-left: -150px;
    opacity: 0.2;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@-moz-keyframes slide-left {
  from {
    margin-left: -150px;
    opacity: 0.2;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    padding-left: 150px;
    opacity: 0.2;
  }

  to {
    padding-left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes slide-right {
  from {
    padding-left: 150px;
    opacity: 0.2;
  }

  to {
    padding-left: 0;
    opacity: 1;
  }
}

@-moz-keyframes slide-right {
  from {
    padding-left: 150px;
    opacity: 0.2;
  }

  to {
    padding-left: 0;
    opacity: 1;
  }
}

.join__community .section__content .text__content h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  margin-bottom: 30px;
  color: var(--blue);
}

.join__community .section__content .text__content p {
  width: 85%;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 2rem;
}

.join__community .section__content .form__content .input__group {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.join__community .section__content .form__content .input__group input,
.join__community .section__content .form__content .input__group textarea {
  width: 80%;
  padding: 10px 20px;
  background: var(--white);
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.join__community .section__content .form__content .input__group textarea {
  height: 180px;
}

.join__community .section__content .form__content .input__group input:focus,
.join__community .section__content .form__content .input__group textarea:focus {
  outline: 0;
  border: 1px solid var(--blue);
  box-shadow:
    0 4px 8px rgba(36, 37, 90, 0.19),
    0 8px 16px rgba(36, 37, 90, 0.2);
}

.join__community
  .section__content
  .form__content
  .input__group
  input::placeholder,
.join__community
  .section__content
  .form__content
  .input__group
  textarea::placeholder {
  color: rgba(36, 37, 90, 0.85);
  font-weight: 700;
}

.join__community .section__content .form__content button {
  font-family: "Montserrat", sans-serif;
  font-size: 0.95rem;
  padding: 8px 30px;
}

.join__community .bottom__art {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: baseline;
  background: #fcfcfcfc;
}

.join__community .shape {
  background: var(--blue);
}

.join__community .semi {
  border-radius: 450px 450px 0 0;
  height: 100px;
  width: 15%;
}
.join__community .semi:first-child {
  margin-left: -50px;
}

.join__community .semi__inverted {
  position: relative;
  z-index: 1;
  height: 140px;
  width: 15%;
}

.join__community .semi__inverted::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fcfcfc;
  border-radius: 0 0 450px 450px;
  width: 100%;
  height: 80%;
  border: 0;
}

main {
  width: 100%;
  height: auto;
}

.nav__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.19);
  z-index: 1000;
  display: none;
}

.nav__modal.open {
  display: block;
}

.nav__modal .inner__content {
  width: 100%;
  height: 100%;
  position: relative;
}

.nav__modal .modal__content {
  width: 55%;
  height: 100%;
  background: var(--white);
  padding: 20px;
}

.nav__modal .modal__content .modal__logo {
  width: 50px;
  height: 50px;
  margin-bottom: 40px;
}

.nav__modal .modal__content .modal__logo img {
  width: 100%;
  height: 100%;
}

.nav__modal .modal__content .links__container {
  width: 100%;
  margin: 80px 0;
}

.nav__modal .modal__content .links__container .link {
  height: 55px;
  display: flex;
  align-items: center;
}

.nav__modal .modal__content .links__container .link:last-child {
  margin-top: 25px;
}

.nav__modal .modal__content .links__container .link a {
  font-size: 0.95rem;
  color: var(--blue);
  font-weight: 700;
  text-decoration: none;
}

.nav__modal .modal__content .links__container .link a:hover {
  color: var(--red);
  transition: 0.2s ease-in;
}

.nav__modal .close__nav {
  font-size: 55px;
  color: var(--red);
  font-weight: 700;
  position: absolute;
  top: 8px;
  right: 20px;
}

.nav__modal .close__nav:hover {
  cursor: pointer;
  transform: scale(1.2);
}

@media screen and (max-width: 1099px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  nav ul {
    margin-left: 0;
    width: 90%;
    display: none;
  }

  nav ul li {
    float: none;
    text-align: left;
    width: 100%;
    padding: 15px 0;
  }
  nav ul li:last-child {
    width: 100%;
  }

  .navbar .fixed__logo {
    margin-left: 0;
  }

  .navbar.fixed {
    padding: 20px;
  }

  .navbar .logo {
    width: 100%;
    display: flex;
    align-items: center;
  }

  nav .logo a img {
    width: 35px;
    height: 35px;
  }

  .navbar .logo .mobile__menu {
    margin-left: auto;
    font-size: 28px;
    display: block;
    color: var(--blue);
    cursor: pointer;
  }

  .navbar .logo.static__logo .mobile__menu {
    color: var(--white);
  }

  .navbar.static {
    padding: 0;
  }

  .join__community {
    height: 840px;
  }

  .join__community .section__content {
    flex-direction: column;
  }

  .join__community .section__content .text__content,
  .join__community .section__content .form__content {
    width: 100%;
  }

  .join__community .section__content .text__content {
    margin-bottom: 25px;
  }

  .join__community .section__content .text__content p,
  .join__community .section__content .form__content .input__group input,
  .join__community .section__content .form__content .input__group textarea {
    width: 100%;
  }

  .join__community .section__content .text__content p {
    font-size: 0.95rem;
  }

  .join__community .semi {
    height: 35px;
  }

  .join__community .semi__inverted {
    height: 50px;
  }

  footer {
    flex-direction: column;
  }

  footer .inner__section {
    width: 100%;
    margin-bottom: 25px;
  }

  .inner__section .footer__section .social__media__links a i {
    font-size: 25px;
  }

  footer .inner__section:last-child {
    width: 100%;
  }

  footer .inner__section .footer__section:last-child .inner__content {
    float: none;
    width: fit-content;
    width: -moz-fit-content;
    margin: 0 auto;
  }

  footer .inner__section .footer__section:last-child .inner__content .logo {
    width: 50px;
    height: 50px;
  }

  .button__red,
  .button__alternate {
    font-size: 0.9rem;
    padding: 7px 28px;
  }
}
