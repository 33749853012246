.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

/* .slideshow .owl-stage-outer {
    height: 30rem;
} */

.training_banner h1 {
  color: white;
  font-weight: 1000px;
  font-size: 4rem;
}

.inner__content .brief {
  display: flex;
  align-items: center;
  color: var(--red);
  font-weight: 700;
  font-size: 0.9rem;
  margin: 10px 0;
}

.inner__content .brief i {
  font-size: 0.8rem;
  margin: 0px 5px 0px 5px;
}

@media screen and (max-width: 800px) {
  .owl-stage-outer {
    height: 18rem;
  }

  .training_banner h1 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 1199px) {
  .training_banner h1 {
    font-size: 3rem;
  }
}
